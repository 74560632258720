export const getMissingOrLessFromSecondArray = (arr1: string[], arr2: string[]): string[] => {
  const countMap1: Record<string, number> = arr1.reduce((acc, item) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const countMap2: Record<string, number> = arr2.reduce((acc, item) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const missingOrLess = Object.entries(countMap1).flatMap(([item, count1]) => {
    const count2 = countMap2[item] || 0;
    return Array(Number(count1) - count2).fill(item);
  });

  return Array.from(new Set(missingOrLess));
};
