import * as React from 'react';
import { useState, SetStateAction, useEffect } from 'react';

import { useRebook } from '../RebookContext';
import RenderFlights from '../RenderFlights';
import { RebookNewOffer } from '../RebookConfirmationPage';
import { RenderAncillaryTable, RenderAmendError } from '../../AncillaryTable/renderAncillaryTable';
import * as TFF from '@tff/types/TFF';
import FlexibleTrack from '../FlexibleTrack';
import { useAmendBooking } from '../../../hooks/use-amend-booking';
import ConfirmPageFooter from './ConfirmPageFooter';
import Header from './Header';
import OriginalBooking from './OriginalBooking';
import ResultPageFooter from './ResultPageFooter';
import Title from './Title';
import SearchAlternatives from './SearchAlternatives';
import ChangeFlightCard from './ChangeFlightCard';
import useFetchFlightAlternatives from '../../../hooks/use-fetch-flight-alternatives';
import AncillariesPage from '../../AncillariesPage/AncillariesPage';
import CreateRebookRows from './createRebookRows';
import { checkTripParameters } from '../Utils/checkTripParameters';
import { ConfirmationDialog } from '../../AncillaryTable/ConfirmationDialog';
import { findNewOnds } from '../Utils/findNewOnds';
import { AtcomDialog } from './AtcomDialog';
import { AtcomCostOverviewDialog } from './AtcomCostOverviewDialog';
import { Ancillary } from '@tff/types/TFF';

export type SelectedAmendBookings = {
  combiHashCodes: string[];
  journeyONDs: string[];
  offerPrices: string[];
};

interface props {
  orderDetails: TFF.OrderDetails;
  journey: TFF.Journey;
  airports: TFF.IAirport[];
  disableButtons: boolean;
  bookingSource: string;
  recordLocator: string;
  selectedAmendBookings: SelectedAmendBookings;
  setSelectedAmendBookings: (value: SetStateAction<SelectedAmendBookings>) => void;
  tfmPnr: string | undefined;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  flightDetails: TFF.FlightDetails;
  midocoOrderNo?: number;
  cancelHandler: () => void;
}

const RebookFlightCard: React.FC<props> = ({
  journey,
  orderDetails,
  airports,
  recordLocator,
  selectedAmendBookings,
  setSelectedAmendBookings,
  tfmPnr,
  rebookCloser,
  flightDetails,
  midocoOrderNo,
  cancelHandler,
}) => {
  const segments: TFF.Segment[] = journey.Segments;

  const {
    dataChanged,
    displayFlights,
    displayConfirmation,
    toggleDisplay,
    toggleConfirmation,
    outboundFlights,
    returnFlights,
    flexibleOutboundFlights,
    flexibleReturnFlights,
    outboundNewJourney,
    returnNewJourney,
    toggleOutboundFlights,
    toggleReturnFlights,
    toggleOutboundNewJourney,
    toggleReturnNewJourney,
    rebookSearchState,
    newRows,
    toggleNewRows,
    seatAncillary,
    toggleSeatAncillary,
  } = useRebook();

  const { setAmendBookingRequest, amendResponse, amendStatus, amendError } = useAmendBooking();

  const fetchFlightAlternatives = useFetchFlightAlternatives(segments, orderDetails);

  const newOffer: TFF.Journey[] = amendResponse?.amendedBooking?.OrderDetails?.Journeys || [];

  const isFirstJourney = journey === orderDetails.Journeys[0];
  const isLastJourney = journey === orderDetails.Journeys[orderDetails.Journeys.length - 1];

  const renderError = isFirstJourney ? flexibleOutboundFlights : flexibleReturnFlights;

  const offerFlights = orderDetails.Journeys.length > 1 ? [outboundNewJourney, returnNewJourney] : [outboundNewJourney];

  const flexibleOffers = isFirstJourney ? flexibleOutboundFlights : flexibleReturnFlights;
  const onOfferSelected = isFirstJourney ? toggleOutboundFlights : toggleReturnFlights;
  const searchDate = isFirstJourney
    ? flexibleOutboundFlights[0]?.StartDate.toString().split('T')[0]
    : flexibleReturnFlights[0]?.StartDate.toString().split('T')[0];
  const selectedFlights = isFirstJourney ? outboundFlights : returnFlights;
  const selectedOffer = isFirstJourney ? outboundNewJourney : returnNewJourney;
  const selectedPrice = selectedAmendBookings.offerPrices;
  const [tableOpen, setTableOpen] = useState<boolean>(false);
  const [errorDialog, setErrorDialog] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [ancillaryRequest, setAncillaryRequest] = useState<boolean>(false);
  const [amendBalanceDue, setAmendBalanceDue] = useState<number | undefined>();
  const [displayRebookConfirmationTable, setDisplayRebookConfirmationTable] = useState<boolean>(false);
  const [debugDialog, setDebugDialog] = useState<boolean>(false);
  const [ancillarySessionId, setAncillarySessionId] = useState<string>();
  const [ancillarySeats, setAncillarySeats] = useState<any>();

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showFailure, setShowFailure] = useState<boolean>(false);
  const [, setDialogOpen] = useState<boolean>(false);
  const [atcomDialog, setAtcomDialog] = useState<boolean>(false);
  const [atcomCostDialog, setAtcomCostDialog] = useState<boolean>(false);

  const outboundSegment: TFF.Segment = orderDetails.Journeys[0].Segments[0];
  const inboundSegment = segments[segments.length - 1];

  const outboundOfferSegment = outboundNewJourney && outboundNewJourney.Segments && outboundNewJourney.Segments[0];
  const inboundOfferSegment = returnNewJourney && returnNewJourney.Segments && returnNewJourney?.Segments[0];
  const fareFamily: string[] =
    orderDetails.Journeys.length > 1
      ? [outboundNewJourney?.FareFamily ?? '', returnNewJourney?.FareFamily ?? '']
      : [outboundNewJourney?.FareFamily ?? ''];

  // state for to set ancillary debug request, does not need type checking
  const [ancillaryData, setAncillaryData] = useState<TFF.PaxData[]>();

  const ancillaryPayload: { outbound: TFF.Journey; inbound?: TFF.Journey } =
    orderDetails.Journeys.length > 1
      ? {
          outbound: outboundNewJourney,
          inbound: returnNewJourney,
        }
      : { outbound: outboundNewJourney };

  const rebookOfferRows = {
    outbound: CreateRebookRows(
      selectedPrice[1],
      selectedPrice[1],
      { originalOnd: orderDetails.Journeys[0].Ond!, segment: outboundSegment },
      { offerOnd: outboundNewJourney.Ond!, offerSegment: outboundOfferSegment },
    ),
    inbound:
      orderDetails.Journeys.length > 1
        ? CreateRebookRows(
            selectedPrice[3] ?? 0,
            selectedPrice[3] ?? 0,
            { originalOnd: orderDetails.Journeys[orderDetails.Journeys.length - 1].Ond!, segment: inboundSegment },
            { offerOnd: returnNewJourney.Ond!, offerSegment: inboundOfferSegment },
          )
        : {},
    totalPrice:
      orderDetails.Journeys.length > 1
        ? parseInt(selectedPrice[3]) + parseInt(selectedPrice[1])
        : parseInt(selectedPrice[1]),
    offerOnd: selectedAmendBookings.journeyONDs,
    outboundOnd: outboundNewJourney.Ond!,
    returnOnd: returnNewJourney?.Ond!,
    seatRows: newRows,
  };

  const hanldeCostDialog = () => {
    setAtcomCostDialog(false);
  };

  const checkNonAtcomAncillaries = (): boolean => {
    const ancillariesList: Ancillary[] = Object.values(flightDetails.OrderDetails.Ancillaries!);
    return ancillariesList.filter(a => a.Type !== 'SEAT').some(a => !a.Note || a.Note !== 'ATCOM');
  };

  const handleRequest = async (dialog?: boolean) => {
    if (checkNonAtcomAncillaries() && dialog !== true) {
      setAtcomDialog(true);
      setAtcomCostDialog(true);
      return;
    }

    setSelectedAmendBookings({ combiHashCodes: [], journeyONDs: [], offerPrices: [] });
    await fetchFlightAlternatives('Outbound');
    if (!isFirstJourney) {
      await fetchFlightAlternatives('Return');
    }
  };

  const handleAmendBooking = () => {
    const outboundJourneyOnd = !checkTripParameters(rebookSearchState.Outbound[0], outboundSegment)
      ? outboundSegment.Ond
      : '';
    const inboundJourneyOnd = !checkTripParameters(rebookSearchState.Return[0], inboundSegment)
      ? inboundSegment.Ond
      : '';
    setAmendBookingRequest({
      action: 'validate',
      bookingSource: 'TUI-NSK',
      combiHashCodes: selectedAmendBookings.combiHashCodes.filter(item => item.length !== 3),
      recordLocator,
      tfmPnr: tfmPnr!,
      journeyONDs: [...new Set([outboundJourneyOnd, inboundJourneyOnd])].filter(Boolean),
    });
  };

  useEffect(() => {}, ['amendBalanceDue']);

  useEffect(() => {
    if (amendStatus === 'SUCCESS' && amendResponse?.query.action === 'validate') {
      toggleOutboundNewJourney(newOffer![0]);
      toggleReturnNewJourney(newOffer![1]);
      toggleDisplay(false);
      toggleConfirmation(true);
      setAmendBalanceDue(amendResponse.bookingSum?.BalanceDue);
    } else if (amendStatus === 'SUCCESS' && amendResponse?.query.action === 'confirm') {
      setDisplayRebookConfirmationTable(true);
      setShowSuccess(true);
      setShowConfirmation(true);
    } else if (amendStatus === 'FAILURE') {
      if (amendResponse?.query.action === 'confirm') {
        setShowFailure(true);
        setShowConfirmation(true);
      }
      setErrorDialog(true);
    }
  }, [amendStatus]);

  const handleAmendBookingConfirm = (paxData: TFF.PaxData[], serviceFee: number): void => {
    setAmendBookingRequest({
      action: 'confirm',
      bookingSource: 'TUI-NSK',
      combiHashCodes: selectedAmendBookings.combiHashCodes.filter(item => item.length !== 3),
      recordLocator,
      tfmPnr: tfmPnr!,
      journeyONDs: [
        ...new Set([orderDetails.Journeys[0]?.Ond!, orderDetails.Journeys[orderDetails.Journeys.length - 1].Ond!]),
      ],
      sessionId: ancillarySessionId,
      paxData: paxData,
      midocoOrderNo: midocoOrderNo,
      serviceFee: serviceFee,
    });
  };

  const handleSelectedAmendBookings = (selectedFlexibleId: string) => {
    const selectedFlexible: TFF.Offer | undefined = isFirstJourney
      ? flexibleOutboundFlights.find(flight => flight.Id === selectedFlexibleId)
      : flexibleReturnFlights.find(flight => flight.Id === selectedFlexibleId);

    setSelectedAmendBookings(prev => {
      const updatedOfferPrices = [...prev.offerPrices];
      const updatedJourneyONDs = [...prev.journeyONDs];
      const updatedCombiHashCodes = [...prev.combiHashCodes];

      const existingIndex = updatedOfferPrices.findIndex(
        (item, index) => index % 2 === 0 && item === selectedFlexible!.Destination,
      );

      const checkCombiHashCodes = updatedCombiHashCodes.findIndex(
        (item, index) => index % 2 === 0 && item === selectedFlexible!.Destination,
      );

      if (existingIndex !== -1) {
        updatedOfferPrices[existingIndex + 1] = selectedFlexible!.TotalPriceDiff!.toString();
      } else {
        updatedOfferPrices.push(selectedFlexible!.Destination, selectedFlexible!.TotalPriceDiff!.toString());
      }

      if (checkCombiHashCodes !== -1) {
        updatedCombiHashCodes[checkCombiHashCodes + 1] = selectedFlexible!.CombiHashCode;
      } else {
        updatedCombiHashCodes.push(selectedFlexible!.Destination, selectedFlexible!.CombiHashCode);
      }

      if (!updatedJourneyONDs.includes(selectedFlexible!.Journey[0].Ond!)) {
        updatedJourneyONDs.push(selectedFlexible!.Journey[0].Ond!);
      }

      return {
        combiHashCodes: updatedCombiHashCodes,
        journeyONDs: updatedJourneyONDs,
        offerPrices: updatedOfferPrices,
      };
    });
  };

  return (
    <>
      {isFirstJourney && (
        <Title
          displayFlights={displayFlights}
          displayConfirmation={displayConfirmation}
          setTableOpen={setTableOpen}
          offerFlights={offerFlights}
          orderDetails={orderDetails}
          recordLocator={recordLocator}
          setAncillarySeats={toggleSeatAncillary}
          setSeatRows={toggleNewRows}
        />
      )}
      {tableOpen && (
        <RenderAncillaryTable
          setTableOpen={setTableOpen}
          journeys={orderDetails.Journeys}
          orderDetails={orderDetails}
          recordLocator={recordLocator}
        />
      )}
      {!displayFlights && !displayConfirmation && <Header isFirstJourney={isFirstJourney} />}
      <ChangeFlightCard
        journey={journey}
        airports={airports}
        orderDetails={orderDetails}
        displayFlights={displayFlights}
        displayConfirmation={displayConfirmation}
      />
      {!displayFlights && !displayConfirmation && isLastJourney && (
        <>
          <AtcomDialog handleRequest={handleRequest} open={atcomDialog} rebookCloser={rebookCloser} />
          <SearchAlternatives
            handleRequest={handleRequest}
            rebookCloser={rebookCloser}
            dataChanged={dataChanged}
            cancelHandler={cancelHandler}
          />
        </>
      )}
      {(displayFlights || displayConfirmation) && (
        <OriginalBooking
          isFirstJourney={isFirstJourney}
          journey={journey}
          airports={airports}
          orderDetails={orderDetails}
        />
      )}
      {displayConfirmation && (
        <RebookNewOffer offer={selectedOffer} airports={airports} orderDetails={orderDetails} price={selectedPrice} />
      )}
      {displayFlights && (
        <>
          <FlexibleTrack flexibleOffers={flexibleOffers} onOfferSelected={onOfferSelected} searchDate={searchDate} />
          <RenderFlights
            originalFlight={orderDetails}
            flights={selectedFlights}
            airports={airports}
            handleSelectedAmendBookings={handleSelectedAmendBookings}
            amendStatus={amendStatus}
            renderError={renderError}
          />
          {isLastJourney && (
            <ResultPageFooter
              rebookCloser={rebookCloser}
              selectedAmendBookings={selectedAmendBookings}
              handleAmendBooking={handleAmendBooking}
              toggleDisplay={toggleDisplay}
            />
          )}
          <RenderAmendError setErrorDialog={setErrorDialog} errorDialog={errorDialog} errorMessage={amendError} />
        </>
      )}
      {displayConfirmation && isLastJourney && (
        <>
          {atcomCostDialog && <AtcomCostOverviewDialog handleClose={hanldeCostDialog} open={atcomCostDialog} />}
          <AncillariesPage
            flightDetails={flightDetails}
            midocoOrderNo={midocoOrderNo}
            rebooking={true}
            showCostTable={showTable}
            setShowCostTable={setShowTable}
            rebookOffers={rebookOfferRows}
            payload={ancillaryPayload}
            ancillaryRequest={ancillaryRequest}
            handleAmendBookingConfirm={handleAmendBookingConfirm}
            sessionId={amendResponse?.sessionId}
            displayRebookConfirmation={displayRebookConfirmationTable}
            rebookingResponse={amendResponse}
            setAncillaryData={setAncillaryData}
            newOnds={findNewOnds(rebookSearchState)}
            setAncillaryId={setAncillarySessionId}
            fareFamily={fareFamily}
            ancillarySeats={seatAncillary}
            amendBalanceDue={amendBalanceDue}
          />
          {isLastJourney && (
            <ConfirmPageFooter
              rebookCloser={rebookCloser}
              setShowTable={setShowTable}
              showTable={showTable}
              setAncillaryRequest={setAncillaryRequest}
              setDebugDialog={setDebugDialog}
              cancelHandler={cancelHandler}
            />
          )}
          {showConfirmation && (
            <ConfirmationDialog
              showConfirmation={showConfirmation}
              showFailure={showFailure}
              showSuccess={showSuccess}
              setShowConfirmation={setShowConfirmation}
              setTableOpen={setDialogOpen}
              flightDetails={flightDetails}
              rebooking={true}
              rebookingResponse={amendResponse}
            />
          )}
        </>
      )}
    </>
  );
};

export default RebookFlightCard;
