import React, { useEffect, useState } from 'react';

import { Checkbox, Radio } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { TFF } from '@tff/types';
import { MissingAncillaries } from './Utils/AncillariesPage/missingAncillaries';
import { AncillaryType } from '../../types/ancillaryTypes';

interface props {
  passenger: TFF.Passenger;
  ancillary: TFF.MetaValue;
  type: string;
  toggleChecked: (passenger: TFF.Passenger, code: string, airlineCode?: string) => boolean;
  handleRadioChange: (checked: boolean, passenger: TFF.Passenger, code: string, airlineCode?: string) => void;
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    passenger: TFF.Passenger,
    code: string,
    airlineCode?: string,
    price?: number,
  ) => void;
  updateSectionPrice: (price: number) => void;
  flightOnd: string;
  displayTable: boolean;
  rebooking?: boolean;
  rebookOffers?: any;
  missingAncillaries: MissingAncillaries;
  ssrAvailability?: Record<string, number>;
}

const FlightAncillaryTableCell: React.FC<props> = ({
  updateSectionPrice,
  handleRadioChange,
  toggleChecked,
  handleCheckboxChange,
  ancillary,
  type,
  passenger,
  flightOnd,
  displayTable,
  rebooking,
  missingAncillaries,
  ssrAvailability,
}) => {
  const exisitingAncillaries = passenger.JourneyExtras?.flatMap(i => (Array.isArray(i.Ssrs) ? i.Ssrs : [])).map(str =>
    str.substring(0, str.lastIndexOf('~')),
  );
  const [checked, setChecked] = useState(toggleChecked(passenger, ancillary.airlineSsrCode));

  const handleCheckboxChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleCheckboxChange(event, passenger, ancillary.code, ancillary.airlineSsrCode, ancillary.price.amount);
  };

  const handleRadioChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleRadioChange(checked, passenger, ancillary.code, ancillary.airlineSsrCode);
  };

  useEffect(() => {
    setChecked(toggleChecked(passenger, ancillary.code));
  }, [toggleChecked(passenger, ancillary.code)]);

  useEffect(() => {
    let updateSum: number = ancillary.price.amount;
    if (!displayTable) {
      updateSum *= 2;
    }
    if (!exisitingAncillaries?.includes(`${flightOnd}~${passenger.Id}~${ancillary.airlineSsrCode}`)) {
      if (ancillary.code !== 'BA0') {
        if (checked) {
          updateSectionPrice(updateSum);
        } else {
          updateSectionPrice(-updateSum);
        }
      }
    }
  }, [checked]);

  const isDisabled = (): boolean => {
    if (ancillary.type === AncillaryType.Sports && !checked) {
      return (
        (ssrAvailability && ssrAvailability[ancillary.code] <= 0) ||
        [...missingAncillaries.inbound, ...missingAncillaries.outbound].some(
          missingAncillary =>
            missingAncillary.JourneyOnd === flightOnd &&
            missingAncillary.PaxID !== passenger.Id &&
            missingAncillary.Type === ancillary.airlineSsrCode,
        )
      );
    }

    return false;
  };

  return (
    <TableCell key={passenger.Id} align="center">
      {type === 'BAGGAGE' ? (
        <Radio
          checked={checked}
          onChange={handleRadioChangeWrapper}
          color={
            exisitingAncillaries?.includes(`${flightOnd}~${passenger.Id}~${ancillary.airlineSsrCode}`)
              ? 'success'
              : 'primary'
          }
        />
      ) : (
        <Checkbox
          checked={checked}
          disabled={isDisabled()}
          onChange={handleCheckboxChangeWrapper}
          color={
            exisitingAncillaries?.includes(`${flightOnd}~${passenger.Id}~${ancillary.airlineSsrCode}`) && !rebooking
              ? 'success'
              : 'primary'
          }
        />
      )}
    </TableCell>
  );
};

export default FlightAncillaryTableCell;
