import { Theme, Typography } from '@mui/material';
import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 4px',
      borderRadius: '4px',
    },
  }),
);

interface HeaderInfoDataProps {
  bookingStatus: string | undefined;
  sourceSystem: string | undefined;
}

const BookingStatusBadge: React.FC<HeaderInfoDataProps> = ({ bookingStatus, sourceSystem }) => {
  const classes = useStyles();
  const intl = useIntl();

  // TODO - Handling error
  if (!bookingStatus || !sourceSystem) {
    return (
      <div className={classes.statusWrapper} style={{ backgroundColor: '#D30D14' }} data-testid="booking-status">
        <Typography variant="h6" sx={{ color: 'white', fontSize: '18px' }}>
          Error
        </Typography>
      </div>
    );
  }

  const statusColor = bookingStatus === 'Confirmed' ? '#30B575' : bookingStatus === 'Cancelled' ? '#D30D14' : '#F3CC49';

  const bookingStatusMessage = () => {
    if (bookingStatus === 'Confirmed') {
      if (sourceSystem === 'TUI-NSK') {
        return intl.formatMessage({ id: 'bookingDetailCard.nskStatus.Confirmed' });
      } else if (sourceSystem === 'TUI-NDC') {
        return intl.formatMessage({ id: 'bookingDetailCard.ndcStatus.Confirmed' });
      }

      return intl.formatMessage({ id: 'bookingDetailCard.status.Confirmed' });
    }

    return intl.formatMessage({ id: `bookingDetailCard.status.${bookingStatus}` });
  };

  return (
    <div className={classes.statusWrapper} style={{ backgroundColor: statusColor }} data-testid="booking-status">
      <Typography
        variant="h6"
        style={{
          color: bookingStatus === 'Cancelled' ? 'white' : '#333333',
          fontSize: '18px',
        }}
      >
        {bookingStatusMessage()}
      </Typography>
    </div>
  );
};

export default BookingStatusBadge;
