import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { TFF } from '@tff/types';
import {
  CancelFlightRequest,
  IAirport,
  IFee,
  RefundDetails,
  RefundDetailsByTicket,
  TypeOfRefund,
} from '@tff/types/TFF';
import CustomButton from '../Button';

import { FieldType } from '../SelectField';
import FormControl from '@mui/material/FormControl';
import { TypeOfCancellationReasonNDC, TypeOfCancellationReasonNSK } from '@tff/types/TFF/cancel-flight-handler';
import RefundSelector, { refundConfigNDC, refundConfigNSK } from '../RefundSelector';
import { FlightIcon, SeatIcon, UserFamilyIcon } from '../icons';

import { toast } from 'react-toastify';
import { ISourceConfig, Message } from '../../models';
import { keycloak } from '../../keycloak';
import { useCancelBooking } from '../../hooks/use-cancel-booking';
import { Col, Row } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import TodayIcon from '@mui/icons-material/Today';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import { useCheckCancelRefund } from '../../hooks/use-check-cancel-refund';

import { format } from 'date-fns';
import CustomDialog from '../Dialogs';
import { useMessages } from '../../hooks/use-messages';
import CancelBookingSummary from './cancel-booking-summary';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { validate } from './validation';
import { FieldArray } from 'react-final-form-arrays';
import { Delete } from '@mui/icons-material';
import { CancellationForm, QueueSelection, TabPanelProps } from './models';
import { useNavigate } from 'react-router-dom';
import ReactJson from 'react-json-view';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '5px',
      borderColor: theme.palette.text.primary,
      margin: '20px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: '#E2F3FE',
      padding: '10px',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      minHeight: '100px',
      width: '100%',
      padding: '20px',
    },
    accountTagRoot: {
      height: '71px',
      width: '247px',
      backgroundColor: '#70CBF4',
      borderRadius: '5px',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    flightsRoot: {
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      backgroundColor: '#70CBF4',
      borderTopStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    flightDetails: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 10px 0px 30px',
    },
    paxDetails: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 10px 0px 30px',
    },
    ancillariesRoot: {
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      backgroundColor: '#f0f9fe',
      borderTopStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    feesAndTaxesRoot: {
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      borderTopStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    seatsRoot: {
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      backgroundColor: '#E2F3FE',
      borderTopStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    upgradesRoot: {
      borderBottomWidth: '2px',
      borderBottomColor: theme.palette.text.primary,
      backgroundColor: '#70CBF4',
      borderBottomStyle: 'double',
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      borderTopStyle: 'double',
      padding: '10px',
      margin: '5px 20px',
    },
    sumRoot: {
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      backgroundColor: '#70CBF4',
      borderTopStyle: 'double',
      padding: '10px',
      margin: '0px 20px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    balanceRoot: {
      backgroundColor: '#092A5E',
      borderBottomRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      padding: '10px',
      margin: '0px 20px 20px 20px',
    },

    divider: {
      borderBottomWidth: '2px',
      borderBottomColor: '#E2F3FE',
      borderBottomStyle: 'double',
    },

    totalRoot: {
      margin: '10px 20px',
      borderTopWidth: '2px',
      borderTopColor: theme.palette.text.primary,
      borderTopStyle: 'double',
    },
    tab: {},
    tabLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 'bold',
      color: 'white',
    },
    tabNumber: {
      height: '40px',
      width: '40px',
      borderRadius: '20px',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#E2F3FE',
      marginRight: '5px',
    },
    buttonGroup: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '20px',
    },
    button: {
      minWidth: 150,
    },
    selectedButton: {
      '&&': {
        color: 'white',
        backgroundColor: '#092A5E',
      },
    },
    passengersWrapper: {},
    globalSelectionRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    selectFieldRoot: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 500,
      alignItems: 'center',
      marginRight: '20px',
    },
    fieldName: {
      fontSize: '16px',
      fontWeight: 'bold',
      alignSelf: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    rootSelect: {
      padding: '0px',
      color: theme.palette.text.primary,
    },
    select: {
      color: theme.palette.text.primary,

      '&:before': {
        borderColor: theme.palette.text.primary,
      },
      '&:after': {
        borderColor: theme.palette.text.primary,
      },
    },
    selectInput: {
      color: '#092A5E',
    },
    queueRoot: {
      padding: '20px',
    },
    queuesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F0F9FE',
      padding: '20px',
      margin: '20px 0px',
    },
    textField: {
      fontWeight: 500,
      padding: '10px',
      border: '1px solid #092A5E',
      backgroundColor: 'white',
    },
    multilineColor: {
      color: theme.palette.text.primary,
      fontSize: '18px',
    },
    multilineQueueColor: {
      color: theme.palette.text.primary,
      fontSize: '18px',
      backgroundColor: '#e6e6e6',
      padding: 5,
    },
    reasonRoot: {
      backgroundColor: '#F0F9FE',
      padding: '20px',
      margin: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
    },
    textFieldFees: {
      fontWeight: 500,
      width: 100,
      padding: '0px 5px',
      border: '1px solid #092A5E',
      backgroundColor: 'white',
    },
    toggleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    paxWrapper: {
      padding: '10px',
      marginRight: '5px',
      display: 'flex',
      flexDirection: 'row',
      borderRadius: '0px',
      borderLeft: '10px solid #092A5E',
      maxWidth: '250px',
      minWidth: '250px',
      margin: '5px',
    },
  }),
);

interface props {
  flightDetails: TFF.FlightDetails;
  serviceFee?: IFee;
  sourceConfig: ISourceConfig;
  onCancel: () => void;
  onConfirm: () => void;
  airports: IAirport[];
  serviceFeeTotalAmount?: number;
  journeysToCancel?: TFF.Journey[];
  servicePackagesFee: number;
}

const CancelBooking: React.FC<props> = ({
  flightDetails,
  serviceFee,
  onCancel,
  sourceConfig,
  airports,
  serviceFeeTotalAmount,
  journeysToCancel,
  servicePackagesFee,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState<number>(1);
  const [cancellationReason, setCancellationReason] = useState<
    TypeOfCancellationReasonNDC | TypeOfCancellationReasonNSK | undefined
  >(undefined);
  const [refundComment, setRefundComment] = useState<string>('');
  const [selectedCancelOnd, setSelectedCancelOnd] = useState<string>('COMPLETE');
  const [cancelRequest, setCancelRequest] = useState<TFF.CancelFlightRequest>();

  const [calculatedServiceCharge, setCalculatedServiceCharge] = useState<number | undefined>(undefined);
  const [openCancelSuccessfulDialog, setOpenCancelSuccessfulDialog] = React.useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<any | undefined>(undefined);
  const [totalOfRefundFlightAmounts, setTotalOfRefundFlightAmounts] = useState<number>(0);
  const [journeysToRender, setJourneysToRender] = useState<TFF.Journey[]>([]);
  const [flightSeatReservations, setFlightSeatReservations] = useState<string[][]>([]);
  const [totalRefundOfSeatReservationAmount, setTotalRefundOfSeatReservationAmount] = useState<number>(0);
  const [totalRefundFeesAndTaxesAmountExcluded, setTotalRefundFeesAndTaxesAmountExcluded] = useState<number>(0);
  const [totalOfRefundAncillariesAmounts, setTotalOfRefundAncillariesAmounts] = useState<number>(0);
  const [refundToAccount, setRefundToAccount] = useState<number>(0);
  const [waivePenaltyFee, setWaivePenaltyFee] = useState<boolean>(true);
  const [foreignCarriers, setForeignCarriers] = useState<string[]>([]);
  const [checkedRefundResponse, setCheckedRefundResponse] = useState<Record<string, any> | undefined>(undefined);
  const [tffRefundDetails, setTffRefundDetails] = useState<RefundDetails | undefined>(undefined);
  const [checkRefundRequest, setCheckRefundRequest] = useState<TFF.CancelFlightRequest | undefined>(undefined);
  const [numberOfPaxType, setNumberOfPaxType] = useState<{ ADT: number; CHD: number; INF: number }>({
    ADT: 0,
    CHD: 0,
    INF: 0,
  });
  const [refundDetails, setRefundDetails] = useState<any>(undefined);
  const [hasPayment, setHasPayment] = useState<boolean | undefined>(undefined);
  const [isVoidable, setIsVoidable] = useState<boolean | undefined>(false);
  const [offerExpirationDateTime, setOfferExpirationDateTime] = useState<Date | string>();
  const [refundType, setRefundType] = useState<TypeOfRefund>('FULL_REFUND');
  const [openError, setOpenError] = useState<boolean>(false);

  let queueSelection: QueueSelection[] = [];

  const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });

  const bookingSource = sourceConfig.BookingSource;

  const { addMessage } = useMessages({
    recordLocator: flightDetails?.OrderSummary?.RecordLocator,
    bookingSource: bookingSource,
  });

  const orderDetails = flightDetails.OrderDetails;
  const orderSummary = flightDetails.OrderSummary;

  const { cancelledBooking, cancelBookingStatus, cancelBookingError } = useCancelBooking({
    cancelFlightRequest: cancelRequest,
  });

  const checkRefundStatusResponse = useCheckCancelRefund({
    checkRefundRequest,
  });

  const defaultRefundTypeOfBookingSource: TypeOfRefund = bookingSource === 'TUI-NSK' ? 'FULL_REFUND' : 'TAX_REFUND';

  const journeys = journeysToCancel ? journeysToCancel : orderDetails?.Journeys;

  const [isServicePackagesRefunded, setIsServicePackagesRefunded] = useState<boolean>(false);
  useEffect(() => {
    if (
      cancellationReason === 'INVOL_CANCEL' &&
      (journeysToCancel?.length === 2 || (orderDetails?.Journeys.length === 1 && journeysToCancel?.length === 1))
    ) {
      setIsServicePackagesRefunded(true);
    } else {
      setIsServicePackagesRefunded(false);
    }
  }, [cancellationReason]);

  useEffect(() => {
    setRefundType(isVoidable ? 'VOID' : defaultRefundTypeOfBookingSource);
  }, [isVoidable]);

  /**
   * This useEffect will be used if the
   * checkRefundRequest returns refundDetails.
   */
  useEffect(() => {
    if (refundDetails) {
      if (refundDetails.error) {
        if (!openError) {
          showError(refundDetails.error);
        }
        setOpenError(true);
        return;
      }

      const passengers = Object.values<TFF.Passenger>(orderDetails?.Passengers);
      const paxTypes = passengers.map(p => p.Type);
      const duplicatePaxTypesSum = {};
      paxTypes.forEach(p => (duplicatePaxTypesSum[p] = duplicatePaxTypesSum[p] ? duplicatePaxTypesSum[p] + 1 : 1));

      if (numberOfPaxType) {
        setNumberOfPaxType(calculateNumberOfPaxType());
      }

      let _totalOfFlightAmounts = 0;
      let _totalFeesAndTaxesAmountExcluded = 0;
      let _totalOfSeatReservationAmount = 0;
      let _totalOfAncillariesAmounts = 0;

      refundDetails.forEach(refundDetails => {
        if (!refundDetails.RefundedOrderItemAmount) {
          return;
        }

        _totalOfFlightAmounts +=
          +refundDetails.OriginalOrderItemAmount?.Amount -
          +refundDetails.DeductedOrderItemAmount?.Amount -
          (+refundDetails.PenaltyAmount?.Amount ?? 0);
        _totalFeesAndTaxesAmountExcluded +=
          +refundDetails.OriginalOrderItemAmount?.TaxSummary?.TotalTaxAmount -
          +refundDetails.DeductedOrderItemAmount?.TaxSummary?.TotalTaxAmount;

        if (bookingSource === 'TUI-NDC-EK') {
          _totalOfFlightAmounts = _totalOfFlightAmounts * +duplicatePaxTypesSum[refundDetails.PaxType];
          _totalFeesAndTaxesAmountExcluded =
            _totalFeesAndTaxesAmountExcluded * +duplicatePaxTypesSum[refundDetails.PaxType];
        }
      });

      setTotalOfRefundFlightAmounts(Math.abs(_totalOfFlightAmounts));
      setTotalRefundFeesAndTaxesAmountExcluded(Math.abs(_totalFeesAndTaxesAmountExcluded));
      setTotalRefundOfSeatReservationAmount(Math.abs(_totalOfSeatReservationAmount));
      setTotalOfRefundAncillariesAmounts(Math.abs(_totalOfAncillariesAmounts));
      //
      const feesMaxValue = (passengers.length ?? 1) * (serviceFee?.fee ?? 0);
      setCalculatedServiceCharge(feesMaxValue);

      const _accountRefund =
        _totalOfFlightAmounts +
        _totalOfSeatReservationAmount +
        _totalOfAncillariesAmounts +
        _totalFeesAndTaxesAmountExcluded -
        feesMaxValue;

      setRefundToAccount(_accountRefund);
    }
  }, [refundDetails]);

  useEffect(() => {
    if (tffRefundDetails) {
      const passengers = Object.values<TFF.Passenger>(orderDetails?.Passengers);

      setTotalOfRefundFlightAmounts(tffRefundDetails!.Totals.TotalRefundableFlightAmount);
      setTotalRefundFeesAndTaxesAmountExcluded(tffRefundDetails!.Totals.TotalRefundableTaxAmount);
      setTotalRefundOfSeatReservationAmount(0);
      setTotalOfRefundAncillariesAmounts(0);

      //
      const feesMaxValue = (passengers.length ?? 1) * (serviceFee?.fee ?? 0);
      setCalculatedServiceCharge(feesMaxValue);

      const _accountRefund = tffRefundDetails.Totals.TotalRefundAmount - feesMaxValue;

      setRefundToAccount(_accountRefund);
    }
  }, [tffRefundDetails]);

  useEffect(() => {
    if (cancellationReason) {
      if (
        bookingSource === 'TUI-NSK' &&
        refundConfigNSK[refundType].indexOf(cancellationReason as TypeOfCancellationReasonNSK) === -1
      ) {
        setRefundComment('');
      } else if (
        bookingSource!.startsWith('TUI-NDC') &&
        refundConfigNDC[refundType].indexOf(cancellationReason as TypeOfCancellationReasonNDC) === -1
      ) {
        setRefundComment('');
      }
    }
  }, [refundType]);

  useEffect(() => {
    // No payment, voidable is true
    if (orderSummary && hasPayment === undefined) {
      setHasPayment(orderSummary?.HasPayments ?? (orderDetails?.Payments && orderDetails?.Payments?.length > -1));
    }

    if (['NSK', 'TUI-NSK'].indexOf(bookingSource!) > -1) {
      return;
    }

    if (checkRefundStatusResponse.status) {
      if (checkRefundStatusResponse?.error) {
        setRefundDetails(checkRefundStatusResponse);
      } else if (checkRefundStatusResponse.checkRefundResponse && !checkedRefundResponse) {
        let _offerExpirationDateTime: Date | string | undefined;
        let _isVoidable = false;

        if (checkRefundStatusResponse.checkRefundResponse.tffRefundDetails) {
          setTffRefundDetails(checkRefundStatusResponse.checkRefundResponse.tffRefundDetails);
          _offerExpirationDateTime =
            checkRefundStatusResponse.checkRefundResponse.tffRefundDetails.OfferExpirationDateTime;
          _isVoidable = checkRefundStatusResponse.checkRefundResponse.tffRefundDetails.IsVoidable;
        }
        // else {
        //
        //   setCheckedRefundResponse(checkRefundStatusResponse.checkRefundResponse);
        //   _offerExpirationDateTime = checkRefundStatusResponse.checkRefundResponse.orderReshopResponse.Response.ReshopOffers.Offer[0].OfferExpirationDateTime;
        //   _isVoidable =
        //     checkRefundStatusResponse.checkRefundResponse.orderReshopResponse.Response.ReshopOffers.Offer[0].IsVoid ??
        //     checkRefundStatusResponse.checkRefundResponse.orderReshopResponse.Response.ReshopOffers.Offer[0].IsVoidable;
        //
        //   setRefundDetails(
        //     checkRefundStatusResponse.checkRefundResponse.orderReshopResponse.Response.ReshopOffers.Offer[0]
        //       .FeeDetails ?? [],
        //   );
        // }
        setOfferExpirationDateTime(_offerExpirationDateTime);

        setIsVoidable(_isVoidable);
      }
    } else {
      // has Payment, needs additional validations
      if (bookingSource!.startsWith('TUI-NDC')) {
        const req: CancelFlightRequest = {
          action: 'check-refund-status',
          recordLocator: orderSummary?.RecordLocator!,
          tfmPnr: orderSummary?.TfmPnr,
          orderId: orderSummary?.BookingId!,
          bookingSource: bookingSource!,
          ndcAgencyDetails: {
            iataNumber: orderSummary?.SourceDetails!.IataNumber!,
            agencyId: orderSummary?.SourceDetails!.AgencyId!,
            officeId: orderSummary?.SourceDetails!.OfficeId!,
          },
        };

        if (flightDetails.OrderSummary?.TargetEnvironment) {
          req.targetEnvironment = flightDetails.OrderSummary.TargetEnvironment;
        }

        setCheckRefundRequest(req);
      }

      // const [cancelRequest, setCancelRequest] = useState<TFF.CancelFlightRequest>();
    }
  }, [checkRefundStatusResponse]);

  useEffect(() => {
    if (cancelBookingStatus === 'FAILURE' && cancelledBooking) {
      const errorMsg = intl.formatMessage({ id: 'cancel.flight.response.failure' }) + '\n' + cancelBookingError;
      toast.error(errorMsg, {
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      handleAddNotice();
      denyCancellationMsg();
    }
    if (cancelBookingStatus === 'CANCELLED' && cancelledBooking) {
      setOpenCancelSuccessfulDialog(true);
      handleAddNotice();
    }
  }, [cancelBookingStatus, cancelledBooking]);

  useEffect(() => {
    if (selectedCancelOnd && !refundDetails) {
      const allFares = Object.values<TFF.Fare>(orderDetails?.Fares!);
      const passengers = Object.values<TFF.Passenger>(orderDetails?.Passengers);
      if (orderSummary?.Carriers) {
        if (bookingSource === 'TUI-NSK') {
          setForeignCarriers(orderSummary.Carriers?.filter(carrier => carrier !== 'X3' && carrier.length < 3));
          // TODO: only for testing purposes!!
          // setForeignCarriers(['EW']);
        }
      }

      let _flightAmounts: number[] = [];
      let _totalOfFlightAmounts = 0;
      let _totalOfAncillariesAmounts = 0;

      let _totalOfSeatReservationAmount = 0;
      let _flightSeatReservations: string[][] = [];
      let _journeysToRender = journeys;
      if (!selectedCancelOnd?.startsWith('COMPLETE')) {
        _journeysToRender = journeys.filter(j => j.Ond === selectedCancelOnd);
      }

      _journeysToRender?.forEach((j, i) => {
        let flightAmount: number = 0;
        allFares.forEach((f: TFF.Fare) => {
          f.FareProducts?.forEach((fp: TFF.FareProduct) => {
            if (fp.JourneyOnd === j.Ond) {
              flightAmount += fp.BaseAmount!;
              _totalOfFlightAmounts += fp.BaseAmount!;
            }
          });
        });

        if (orderDetails?.Ancillaries) {
          const ancillaries = Object.values(orderDetails?.Ancillaries);
          ancillaries.forEach(ancillary => {
            if (ancillary.JourneyOnd === j.Ond) {
              if (ancillary.Type === 'SEAT') {
                _flightSeatReservations[i] = _flightSeatReservations[i] ?? [];
                _flightSeatReservations[i].push(ancillary.Value!);
                _totalOfSeatReservationAmount += ancillary.TotalAmount ?? 0;
              } else {
                _totalOfAncillariesAmounts += ancillary.TotalAmount ?? 0;
              }
            }
          });
        }

        _flightAmounts[i] = flightAmount;
      });

      setTotalOfRefundFlightAmounts(-Math.abs(_totalOfFlightAmounts));
      setTotalRefundOfSeatReservationAmount(-Math.abs(_totalOfSeatReservationAmount));
      setTotalOfRefundAncillariesAmounts(-Math.abs(_totalOfAncillariesAmounts));

      setJourneysToRender(_journeysToRender);
      setFlightSeatReservations(_flightSeatReservations);

      const feesMaxValue = (passengers.length ?? 1) * (serviceFee?.fee ?? 0);
      setCalculatedServiceCharge(feesMaxValue);

      setRefundToAccount(
        _totalOfFlightAmounts + _totalOfSeatReservationAmount + _totalOfAncillariesAmounts - feesMaxValue,
      );
    }
  }, [selectedCancelOnd]);

  const showError = (error: any) => {
    let errorMsg = error.message;
    setOpenError(false);

    if (Array.isArray(error)) {
      // in case of NDC sometimes an array of errormessages are received:

      errorMsg = error
        // @ts-ignore
        .map<string>(p => {
          return p.DescText;
        })
        .join('\n');
    }
    toast.error(errorMsg, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (cancelBookingError && !openError) {
      showError(cancelBookingError);
      setOpenError(true);
    }
  }, [cancelBookingError]);

  useEffect(() => {
    if (cancellationReason === 'FARE_RULES') {
      setRefundComment(intl.formatMessage({ id: `${cancellationReason}` }));
    } else {
      setRefundComment('');
    }
  }, [cancellationReason]);

  const getAirport = (id: string): string | undefined => {
    if (!airports) {
      return id;
    }
    const airport = airports.find(ap => ap.id === id);
    if (!airport) {
      return;
    }
    return `${airport.nameDE} (${id})`;
  };

  const handleRefundTypeSelection = (event: React.MouseEvent<HTMLElement>, refundType: TypeOfRefund) => {
    if (refundType !== null) {
      setRefundType(refundType);
    }
  };

  const handleCancelBookingSelection = (event: React.MouseEvent<HTMLElement>, selection: string) => {
    if (selection !== null) {
      setSelectedCancelOnd(selection);
    }
  };

  const handleAddNotice = () => {
    const msg = new Message(
      keycloak?.profile?.email ?? 'UNKNOWN',
      orderSummary?.RecordLocator!,
      'CALLCENTER',
      'LOG_ENTRY',
      bookingSource!,
      'CANCEL_BOOKING',
    );
    msg.content = 'Cancel Booking';
    addMessage(msg);
  };

  const denyCancellationMsg = () => {
    const msg = new Message(
      keycloak?.profile?.email ?? 'UNKNOWN',
      orderSummary?.RecordLocator!,
      'CALLCENTER',
      'NOTICE',
      bookingSource!,
      'CANCEL_BOOKING',
    );
    msg.content = `DECLINED Cancelrequest based on (${cancellationReason}). Reason: ${refundComment}`;
    addMessage(msg);
    onCancel();
  };

  const calculateNumberOfPaxType = (): { ADT: number; CHD: number; INF: number } => {
    const res = { ADT: 0, CHD: 0, INF: 0 };
    const passengers = Object.values<TFF.Passenger>(orderDetails?.Passengers);

    passengers.forEach(passenger => {
      res[passenger.Type]++;
    });
    return res;
  };

  const getRefundForTicketNumber = (ticketNumber: string): RefundDetailsByTicket => {
    const fareForTicketNumber = Object.values(flightDetails.OrderDetails.Fares).find(
      f => f.TicketNumber === ticketNumber,
    );
    const defaultResponse: RefundDetailsByTicket = {
      ticketNumber,
      paxType: fareForTicketNumber?.PaxType ?? 'UNKNOWN',
      fareAmount: 0,
      taxAmount: 0,
      ssrAmount: 0,
    };

    if (!fareForTicketNumber) {
      return defaultResponse;
    }

    if (checkedRefundResponse?.orderReshopResponse?.Response?.ReshopOffers?.Offer?.length) {
      const reshopOffer = checkedRefundResponse.orderReshopResponse.Response.ReshopOffers.Offer[0];
      if (!reshopOffer.IsVoidable && reshopOffer.FeeDetails) {
        const feesForPax = reshopOffer.FeeDetails.find(
          refundDetails => refundDetails.PaxType === fareForTicketNumber.PaxType,
        );
        const numberOfPaxe = numberOfPaxType[fareForTicketNumber.PaxType];
        if (feesForPax) {
          const penaltyAmount = feesForPax.PenaltyAmount?.Amount ?? 0;
          defaultResponse.paxType = fareForTicketNumber?.PaxType;
          defaultResponse.fareAmount =
            ((feesForPax.OriginalOrderItemAmount.Amount ?? 0) -
              (feesForPax.DeductedOrderItemAmount.Amount ?? 0) -
              penaltyAmount) /
            (bookingSource !== 'TUI-NDC-EK' ? numberOfPaxe : 1);
          defaultResponse.taxAmount =
            ((feesForPax.OriginalOrderItemAmount.TaxSummary.TotalTaxAmount ?? 0) -
              (feesForPax.DeductedOrderItemAmount.TaxSummary.TotalTaxAmount ?? 0)) /
            (bookingSource !== 'TUI-NDC-EK' ? numberOfPaxe : 1);
        }
      }
    }

    return defaultResponse;
  };

  const confirmCancellation = (formValues?: CancellationForm) => {
    let refundByTicketNumber: RefundDetailsByTicket[] = [];
    if (checkedRefundResponse && checkedRefundResponse.orderReshopResponse) {
      const existingTicketNumbers = Object.values(flightDetails.OrderDetails.Fares).map(f => f.TicketNumber);
      if (existingTicketNumbers.length > 0) {
        refundByTicketNumber = existingTicketNumbers.map<RefundDetailsByTicket>(t => getRefundForTicketNumber(t!));
      }
    }

    const cancelRequest: CancelFlightRequest = {
      action: 'cancel',
      bookingSource: bookingSource!,
      recordLocator: orderSummary?.RecordLocator!,
      typeOfRefund: refundType,
      cancellationReason: cancellationReason,
      serviceChargeTui: calculatedServiceCharge,
      serviceChargeSource: 0,
      afterPayment: hasPayment,
      tfmPnr: orderSummary?.TfmPnr!,
      waivePenaltyFee: waivePenaltyFee,
      bookingComment: cancellationReason + (refundComment ? '$$' + refundComment : ''),
      refundServicePackages: isServicePackagesRefunded,
    };

    if (flightDetails.OrderSummary?.TargetEnvironment) {
      cancelRequest.targetEnvironment = flightDetails.OrderSummary.TargetEnvironment;
    }
    if (
      checkRefundStatusResponse &&
      checkRefundStatusResponse.checkRefundResponse?.tffRefundDetails?.RefundedOfferDetails?.length
    ) {
      cancelRequest.offerId =
        checkRefundStatusResponse.checkRefundResponse.tffRefundDetails.RefundedOfferDetails[0].OfferID;
    }

    if (tffRefundDetails && tffRefundDetails.Totals.TotalRefundAmount && !tffRefundDetails.IsVoidable) {
      cancelRequest.expectedRefundAmount = tffRefundDetails.Totals.TotalRefundAmount;
    }
    if (journeysToCancel && journeysToCancel.length < flightDetails.OrderDetails.Journeys.length) {
      cancelRequest.journeyOndsToCancel = journeysToCancel.map(j => j.Ond!);
    }

    if (refundByTicketNumber && refundByTicketNumber.length > 0) {
      // @ts-ignore
      cancelRequest.refundDetailsByTicketNumber = refundByTicketNumber;
    }

    if (bookingSource!.startsWith('TUI-NDC')) {
      cancelRequest.orderId = orderSummary?.BookingId;
      cancelRequest.ndcAgencyDetails = {
        agencyId: orderSummary?.SourceDetails?.AgencyId ?? '',
        iataNumber: orderSummary?.SourceDetails?.IataNumber ?? '',
        officeId: orderSummary?.SourceDetails?.OfficeId ?? '',
      };
    } else {
      cancelRequest.agent = 'TFFX3';
    }

    if (!queueSelectionsAreValid(formValues)) {
      const errorMsg = intl.formatMessage({ id: 'cancel.flight.queue.incomplete.failure' });
      toast.error(errorMsg, {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (formValues && formValues?.queueSelections && formValues?.queueSelections.length > 0) {
      const filteredQueues = formValues?.queueSelections.filter(q => q.queue);
      cancelRequest.queues = filteredQueues.map<{ queue: string; note?: string }>(qp => {
        return { queue: qp.queue, note: qp.queueNote! };
      });
    }

    setOpenConfirmDialog(cancelRequest);
  };

  const queueSelectionsAreValid = (formValues?: CancellationForm): boolean => {
    let ret = true;
    if (formValues?.queueSelections && formValues?.queueSelections.length > 0) {
      formValues?.queueSelections.forEach(queue => {
        if (queue.queue === '' || queue.queueNote === '') {
          ret = false;
        }
      });
    }
    return ret;
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const renderSelectedFlightDetails = (showFares = true) => {
    if (!selectedCancelOnd) {
      return <></>;
    }

    return journeysToRender?.map((j, i) => {
      return (
        j?.Segments && (
          <div key={i}>
            <Typography
              component={'span'}
              variant="h6"
              color="primary"
              style={{ paddingBottom: '20px', paddingTop: '20px' }}
              noWrap={true}
            >
              <Row>
                <Col xs={2} style={{ marginLeft: '10px' }}>
                  <div className="d-flex flex-row align-items-center">
                    <AirplanemodeActiveIcon color="primary" style={{ marginRight: '2px' }} />
                    {j?.Segments[0].MarketingCarrier} {j?.Segments[0].FlightNumber}
                  </div>
                </Col>
                <Col xs={2}>
                  <div className="d-flex flex-row align-items-center">
                    <TodayIcon color="primary" style={{ marginRight: '2px' }} />
                    {moment(j?.Segments[0]?.DepartureTime).format('DD.MM.YYYY')}
                  </div>
                </Col>
                <Col xs={6}>
                  <Row className="d-flex flex-row align-items-center justify-content-between">
                    <Col className="d-flex flex-row align-items-center">
                      <FlightTakeoffIcon color="primary" style={{ marginRight: '2px' }} />
                      {getAirport(j?.Segments[0]?.Origin)}
                    </Col>
                    <Col className="d-flex flex-row align-items-center">
                      <FlightLandIcon color="primary" style={{ marginRight: '2px' }} />
                      {getAirport(j?.Segments[j.Segments.length - 1]?.Destination)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Typography>
            {i < journeysToRender.length - 1 && (
              <Row style={{ paddingBottom: '5px', backgroundColor: '#E2F3FE' }}></Row>
            )}
          </div>
        )
      );
    });
  };

  const renderSelectedSeatDetails = () => {
    if (!selectedCancelOnd) {
      return <>Noting selected</>;
    }

    return journeysToRender?.map((j, i) => {
      return (
        j?.Segments && (
          <Typography
            component={'span'}
            key={i}
            variant="h6"
            color="primary"
            style={{ marginTop: '20px' }}
            noWrap={true}
          >
            <Row>
              <Col xs={2}>
                <div className="d-flex flex-row align-items-center">
                  <TodayIcon color="primary" style={{ marginRight: '2px' }} />
                  {moment(j?.Segments[0]?.DepartureTime).format('DD.MM.YYYY')}
                </div>
              </Col>
              <Col xs={6}>
                <Row className="d-flex flex-row align-items-center justify-content-between">
                  <Col className="d-flex flex-row align-items-center">
                    <FlightTakeoffIcon color="primary" style={{ marginRight: '2px' }} />
                    {getAirport(j?.Segments[0]?.Origin)}
                  </Col>
                  <Col className="d-flex flex-row align-items-center">
                    <FlightLandIcon color="primary" style={{ marginRight: '2px' }} />
                    {getAirport(j?.Segments[j.Segments.length - 1]?.Destination)}
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <div className="d-flex flex-row align-items-center">
                  <AirlineSeatReclineExtraIcon color="primary" />
                  <Typography component={'span'} variant="h6" color="primary">
                    {flightSeatReservations[i] ? flightSeatReservations[i].join(', ') : 'No reservation found'}
                  </Typography>
                </div>
              </Col>
            </Row>
          </Typography>
        )
      );
    });
  };

  const renderSelectedPaxDetails = () => {
    if (!selectedCancelOnd) {
      return <></>;
    }
    return (
      <Row style={{ margin: 0, marginTop: '10px' }}>
        {Object.values<TFF.Passenger>(orderDetails.Passengers).map((p, index) => {
          return (
            <Tooltip title={p.Type} key={index}>
              <Paper elevation={2} className={classes.paxWrapper} key={index}>
                {/*{ p.Type === 'ADT' ? <PersonIcon color="primary"></PersonIcon> : <ChildFriendlyIcon color="primary" />}*/}
                <Typography component={'span'} color="primary" variant="body1">
                  {p.FirstName} {p.LastName} ({p.Type}){/*{dob ? `(${dob})` : ''}*/}
                </Typography>
              </Paper>
            </Tooltip>
          );
        })}
      </Row>
    );
  };

  const renderFlights = (showFares = true) => {
    return (
      <div className={classes.flightsRoot} style={{ backgroundColor: '#F0F9FE' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FlightIcon style={{ fill: '#092A5E' }} />
          <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'cancel.flights' })}</strong>
          </Typography>
        </div>
        {renderSelectedFlightDetails(showFares)}
      </div>
    );
  };

  const renderPaxs = () => {
    return (
      <div className={classes.flightsRoot} style={{ marginTop: '10px', marginBottom: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <UserFamilyIcon />
          <Typography
            component={'span'}
            variant="h6"
            color="primary"
            style={{ marginLeft: '10px', textTransform: 'uppercase' }}
          >
            <strong>{intl.formatMessage({ id: 'cancel.passengers' })}</strong>
          </Typography>
        </div>
        {renderSelectedPaxDetails()}
      </div>
    );
  };

  const renderSeats = () => {
    return (
      <div className={classes.seatsRoot} style={{ backgroundColor: '#F0F9FE' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SeatIcon />
          <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'cancel.seatReservations' })}</strong>
          </Typography>
        </div>
        {renderSelectedSeatDetails()}
      </div>
    );
  };

  const taxes = useMemo(() => {
    let _taxes = 0;
    if (!orderDetails.Fares) {
      return _taxes;
    }
    const fares = Object.values(orderDetails.Fares);
    fares.forEach(f => {
      if (journeysToCancel?.length === 1) {
        const fareProducts = f.FareProducts.find(fp => fp.JourneyOnd === journeysToCancel[0].Ond);
        _taxes += fareProducts?.TotalFeesAndTaxesAmountExcluded ?? 0;
        _taxes += fareProducts?.TotalFeesAndTaxesAmountIncluded ?? 0;
      } else {
        _taxes += f.TotalFeesAndTaxesAmountIncluded ?? 0;
        _taxes += f.TotalFeesAndTaxesAmountExcluded ?? 0;
      }
    });
    return _taxes;
  }, [orderDetails]);
  useEffect(() => {
    if (isServicePackagesRefunded) {
      setRefundToAccount(prevRefundToAccount => prevRefundToAccount + servicePackagesFee);
    } else {
      setRefundToAccount(prevRefundToAccount => prevRefundToAccount - servicePackagesFee);
    }
  }, [isServicePackagesRefunded]);

  const renderSum = () => {
    let farePrice =
      bookingSource === 'TUI-NSK'
        ? Math.abs(totalOfRefundFlightAmounts) - taxes
        : (orderSummary?.TotalFarePrice ?? 0) - taxes;
    let ancillaries = orderSummary?.TotalAncillaryPrice ?? 0;
    let serviceFee = serviceFeeTotalAmount ?? 0;

    let farePriceRefundable = totalOfRefundFlightAmounts;
    let ancillariesRefundable = totalOfRefundAncillariesAmounts + totalRefundOfSeatReservationAmount;
    let taxesRefundable = totalRefundFeesAndTaxesAmountExcluded;
    let serviceFeeRefundable = 0;

    if (sourceConfig.SourceGroup === 'TUI_NSK' || isVoidable) {
      farePriceRefundable = farePrice;
      ancillariesRefundable = ancillaries;
      taxesRefundable = taxes;
      serviceFeeRefundable = 0;
      if (isVoidable) {
        setRefundToAccount(
          Math.abs(farePriceRefundable + ancillariesRefundable + taxesRefundable + serviceFeeRefundable) -
            (calculatedServiceCharge ?? 0),
        );
      }
    }

    return (
      <CancelBookingSummary
        farePrice={farePrice}
        farePrice_refundable={farePriceRefundable}
        ancillaries={ancillaries}
        ancillaries_refundable={ancillariesRefundable}
        taxes={taxes}
        taxes_refundable={taxesRefundable}
        serviceFee={serviceFee}
        serviceFee_refundable={serviceFeeRefundable}
        servicePackagesFee={servicePackagesFee}
        servicePackagesFee_refundable={isServicePackagesRefunded ? servicePackagesFee : 0}
      />
    );
  };

  const renderServicePackagesRefund = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: '20px',
          }}
        >
          <Checkbox
            checked={isServicePackagesRefunded}
            disabled={
              !(
                journeysToCancel?.length === 2 ||
                (orderDetails?.Journeys.length === 1 && journeysToCancel?.length === 1)
              ) || !servicePackagesFee
            }
            onChange={event => setIsServicePackagesRefunded(event.target.checked)}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography>Refund service packages.</Typography>
        </Box>
        <Alert
          severity="info"
          variant="filled"
          sx={{
            background: 'rgba(216, 226, 253, 1)',
            marginLeft: '20px',
            marginTop: '10px',
          }}
        >
          {cancellationReason === 'INVOL_CANCEL' &&
          (journeysToCancel?.length === 2 || (orderDetails?.Journeys.length === 1 && journeysToCancel?.length === 1))
            ? 'As the reason for cancellation is involuntary cancellation, a refund of the Service Package cost is allowed.'
            : 'A refund of the Service Package cost is not allowed.'}
        </Alert>
      </>
    );
  };

  const renderServiceFee = () => {
    return (
      <div
        className={classes.sumRoot}
        style={{ borderStyle: 'none', margin: '5px 20px', borderRadius: 0, backgroundColor: '#F0F9FE' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography
            component={'span'}
            variant="h6"
            color="primary"
            style={{ marginLeft: '10px', textTransform: 'uppercase' }}
          >
            <strong>{intl.formatMessage({ id: 'cancel.cancellationFees' })}</strong>
          </Typography>
          <TextField
            variant="standard"
            onChange={handleCancellationFees}
            className={classes.textFieldFees}
            value={calculatedServiceCharge}
            InputProps={{
              disableUnderline: true,
              className: classes.multilineColor,
              defaultValue: calculatedServiceCharge,
              inputProps: { min: 0 },
            }}
            type="number"
          />
        </div>
      </div>
    );
  };

  const renderWaiveFee = () => {
    return (
      <div className={classes.sumRoot} style={{ borderStyle: 'none', margin: '5px 20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography
            component={'span'}
            variant="h6"
            color="primary"
            style={{ marginLeft: '10px', textTransform: 'uppercase' }}
          >
            <strong>{intl.formatMessage({ id: 'cancel.waiveFee' })}</strong>
          </Typography>
          <Checkbox color="primary" onChange={event => handleWaiveFeeSelection(event)} checked={waivePenaltyFee} />
        </div>
      </div>
    );
  };

  const renderRefunds = () => {
    return (
      <div className={classes.balanceRoot}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography
            component={'span'}
            variant="h6"
            style={{ marginLeft: '10px', color: 'white', textTransform: 'uppercase' }}
          >
            <strong>{intl.formatMessage({ id: 'cancel.refunds' })}</strong>
          </Typography>
          <Typography component={'span'} variant="h6" style={{ marginLeft: '10px', color: 'white' }}>
            <strong>{numberFormatter.format(Number(refundToAccount))}</strong>
          </Typography>
        </div>
      </div>
    );
  };

  const renderQueue = () => {
    return (
      <div className={classes.queueRoot}>
        {foreignCarriers && foreignCarriers.length > 0 && (
          <Alert severity="warning" style={{ fontSize: '18px' }}>
            <AlertTitle>
              <strong>Warning</strong>
            </AlertTitle>
            Foreign Carriers! Please select a Queue
          </Alert>
        )}
        <div className={classes.queuesWrapper}>
          <FieldArray
            name={'queueSelections'}
            rerenderOnEveryChange
            initialValue={queueSelection}
            validate={value => (value && value.length > 0 ? undefined : 'required')}
          >
            {({ fields }) => (
              <>
                {fields.map((item, index) => (
                  <Grid container spacing={2} style={{ marginBottom: 10 }} key={index}>
                    <Grid item xs={2} container>
                      <Field name={`${item}.queue`} required label="Queue">
                        {({ input, meta }) => (
                          <TextField
                            variant="standard"
                            {...input}
                            label="Queue"
                            helperText={(meta.dirty || meta.submitFailed) && meta.touched && meta.error}
                            inputProps={{ maxLength: 6, style: { textTransform: 'uppercase' } }}
                            fullWidth
                          />
                        )}
                      </Field>

                      {/*<Field*/}
                      {/*  name={`${item}.queue`}*/}
                      {/*  required*/}
                      {/*  label="Queue"*/}
                      {/*  // inputProps={{ maxLength: 6, style: { textTransform: 'uppercase' } }}*/}
                      {/*  render={({ input, meta }) => (*/}
                      {/*    <AutoCompleteField*/}
                      {/*      id="queue-autocomplete"*/}
                      {/*      inputValue={queueInputValue}*/}
                      {/*      options={cancelQueues.map(q => q.id)}*/}
                      {/*      getOptionLabel={queue => queue}*/}
                      {/*      input={input}*/}
                      {/*      setInputValue={setQueueInputValue}*/}
                      {/*      onChange={() => {*/}
                      {/*        setQueueInputValue('');*/}
                      {/*      }}*/}
                      {/*      fullWidth*/}
                      {/*      label="Queue"*/}
                      {/*      required*/}
                      {/*      error={meta.touched && meta.error}*/}
                      {/*      //inputProps={{ maxLength: 6, style: { textTransform: 'uppercase' } }} -> APP CRASHES*/}
                      {/*    />*/}
                      {/*  )}*/}
                      {/*/>*/}
                    </Grid>
                    <Grid item xs={8}>
                      <Field
                        name={`${item}.queueNote`}
                        required
                        render={({ input, meta }) => (
                          <TextField
                            variant="standard"
                            {...input}
                            required
                            fullWidth
                            label="Queue Note"
                            error={meta.touched && meta.error}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item container alignItems="flex-end" xs={2}>
                      <IconButton onClick={() => fields.remove(index)} style={{ padding: 0 }} size="large">
                        <Delete color="secondary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          fields.push({ queue: '', queueNote: '' });
                        }}
                      >
                        Add Queue
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </FieldArray>
        </div>
      </div>
    );
  };

  const handleRefundReason = (selectedField: FieldType): void => {
    if (!selectedField) {
      return;
    }
    setCancellationReason(selectedField.key as TypeOfCancellationReasonNDC | TypeOfCancellationReasonNSK);
  };

  const handleRefundComment = (event: any) => {
    if (!event) {
      return;
    }
    setRefundComment(event.currentTarget.value);
  };

  const handleWaiveFeeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    setWaivePenaltyFee(event.currentTarget.checked);
  };

  const handleCancellationFees = (event: any) => {
    if (!event) {
      return;
    }
    const value = +event.currentTarget.value;

    setRefundToAccount(
      Math.abs(
        totalOfRefundFlightAmounts +
          totalRefundOfSeatReservationAmount +
          totalOfRefundAncillariesAmounts +
          totalRefundFeesAndTaxesAmountExcluded,
      ) - value,
    );
    setCalculatedServiceCharge(value);
  };

  const handleCloseConfirmationDialog = () => {
    denyCancellationMsg();
    setOpenConfirmDialog(false);
  };

  const handleConformConfirmationDialog = () => {
    let action = 'CANCEL_BOOKING';
    let content = `CONFIRMED Cancel booking based on (${cancellationReason}). Reason: ${refundComment}`;
    if (journeysToCancel && journeysToCancel.length < flightDetails.OrderDetails.Journeys.length) {
      action = 'CANCEL_JOURNEY';
      content = `CONFIRMED Cancel journey (${journeysToCancel[0].Ond}) request based on (${cancellationReason}). Reason: ${refundComment}`;
    }

    setOpenConfirmDialog(false);
    const msg = new Message(
      keycloak?.profile?.email ?? 'UNKNOWN',
      orderSummary?.RecordLocator!,
      'CALLCENTER',
      'NOTICE',
      bookingSource!,
      action,
    );
    msg.content = content;

    addMessage(msg);

    setCancelRequest(openConfirmDialog);
  };

  const renderConfirmationDialog = () => {
    return (
      <CustomDialog
        initialOpen={!!openConfirmDialog}
        onCancel={handleCloseConfirmationDialog}
        onConfirm={handleConformConfirmationDialog}
        width="md"
        cancelButtonText={intl.formatMessage({ id: 'cancel.flight.no' })}
        confirmButtonText={intl.formatMessage({ id: 'cancel.flight.yes' })}
        title={intl.formatMessage({ id: 'cancel.flight.attention' })}
        warning
      >
        <Typography component={'span'} variant="h6" color="primary">
          {intl.formatMessage({ id: 'cancel.flight.content' })}
        </Typography>
      </CustomDialog>
    );
  };

  const renderSuccessfullyCancelResult = () => {
    return (
      <CustomDialog
        initialOpen={!!openCancelSuccessfulDialog}
        onConfirm={() => {
          navigate('/tff/order/search');
        }}
        width="md"
        confirmButtonText={intl.formatMessage({ id: 'cancel.flight.back_to_search' })}
        closeButton={false}
        title={intl.formatMessage({ id: 'cancel.flight.successfullycancelled.title' })}
      >
        <Typography component={'span'} variant="h6" color="primary">
          {intl.formatMessage({ id: 'cancel.flight.successfullycancelled.msg' })}
        </Typography>
      </CustomDialog>
    );
  };

  const flightCancellationOverview = () => {
    return (
      <Form<CancellationForm>
        onSubmit={confirmCancellation}
        mutators={{ ...arrayMutators }}
        validate={validate}
        render={({ values, valid }) => {
          return (
            <div>
              <ToggleButtonGroup
                className={classes.buttonGroup}
                value={refundType}
                exclusive
                onChange={handleRefundTypeSelection}
              >
                <ToggleButton
                  value={defaultRefundTypeOfBookingSource}
                  className={classes.button}
                  classes={{ selected: classes.selectedButton }}
                  // disabled={isVoidable}
                >
                  {intl.formatMessage({
                    id: defaultRefundTypeOfBookingSource === 'FULL_REFUND' ? 'cancel.fullRefund' : 'cancel.taxRefund',
                  })}
                </ToggleButton>

                <ToggleButton
                  value={'VOID'}
                  className={classes.button}
                  classes={{ selected: classes.selectedButton }}
                  disabled={!isVoidable}
                >
                  {intl.formatMessage({ id: 'cancel.void' })}
                </ToggleButton>
              </ToggleButtonGroup>

              <div className={classes.reasonRoot}>
                <Typography component={'span'} color="primary" variant="h6" className={classes.title}>
                  {intl.formatMessage({ id: 'cancel.reasonForRefund' })}
                </Typography>
                <div className={classes.selectFieldRoot}>
                  <FormControl variant="standard" className={classes.formControl} style={{ marginLeft: '0px' }}>
                    <RefundSelector
                      refundType={refundType}
                      onChange={handleRefundReason}
                      bookingSource={bookingSource!}
                    />
                  </FormControl>
                </div>
                <TextField
                  variant="standard"
                  id="standard-multiline-flexible"
                  multiline
                  minRows={4}
                  placeholder={intl.formatMessage({ id: 'cancel.addAReason' })}
                  onChange={handleRefundComment}
                  value={refundComment}
                  className={classes.textField}
                  InputProps={{ disableUnderline: true, className: classes.multilineColor }}
                  disabled={!cancellationReason}
                />
              </div>

              <Typography
                component={'span'}
                variant="h6"
                color="primary"
                style={{
                  padding: '20px',
                  textTransform: 'uppercase',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <strong>{intl.formatMessage({ id: 'cancel.cancellationOverview' })}</strong>
                <strong>IN EUR</strong>
              </Typography>
              {renderFlights()}
              {renderPaxs()}
              {renderSeats()}
              {renderSum()}
              {renderServiceFee()}
              {sourceConfig.SourceGroup === 'TUI_NSK' && renderWaiveFee()}
              {renderRefunds()}
              {renderServicePackagesRefund()}
              {sourceConfig.SourceGroup === 'TUI_NSK' && renderQueue()}
              <div className={classes.buttonsWrapper}>
                <CustomButton
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={denyCancellationMsg}
                  style={{ marginRight: '20px' }}
                >
                  {intl.formatMessage({ id: 'cancel.back' })}
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  color="danger"
                  size="large"
                  onClick={() => confirmCancellation(values)}
                  disabled={
                    cancelBookingStatus !== undefined ||
                    !refundComment ||
                    (process.env.STAGE === 'PROD' && bookingSource !== 'TUI-NSK')
                  }
                >
                  {intl.formatMessage({ id: 'cancel.confirmCancellation' })}
                </CustomButton>
              </div>
            </div>
          );
        }}
      />
    );
  };

  const renderSelection = () => {
    if (!journeys?.length) {
      return <></>;
    }
    const journeysOnds: string[] = journeys?.map(j => j.Ond) as string[];

    return (
      <div>
        <Typography component={'span'} color="primary" variant="h6">
          <strong>{intl.formatMessage({ id: 'cancel.selectFlightToCancel' })}</strong>
        </Typography>

        <div className={classes.toggleWrapper}>
          <ToggleButtonGroup
            className={classes.buttonGroup}
            value={selectedCancelOnd}
            exclusive
            onChange={handleCancelBookingSelection}
            style={{ justifyContent: 'left', marginLeft: '0px' }}
          >
            <ToggleButton
              value={`COMPLETE (${journeysOnds.join(' ')})`}
              className={classes.button}
              classes={{ selected: classes.selectedButton }}
            >
              {intl.formatMessage({ id: 'cancel.completeBooking' })}
            </ToggleButton>
            {journeysOnds.length > 1 &&
              journeysOnds.map((journey, index) => (
                <ToggleButton
                  key={index}
                  value={journey}
                  className={classes.button}
                  classes={{ selected: classes.selectedButton }}
                >
                  {journey}
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
          <CustomButton
            variant="contained"
            color="danger"
            size="large"
            onClick={() => setTabValue(1)}
            disabled={!selectedCancelOnd}
            style={{ margin: '20px', float: 'right' }}
          >
            {intl.formatMessage({ id: 'cancel.confirmCancellation' })}
          </CustomButton>
        </div>

        {/*<div className={classes.passengersWrapper}>*/}
        {/*  <CancelBookingSelection*/}
        {/*    selectedOnd={selectedCancelOnd}*/}
        {/*    flight={orderDetails}*/}
        {/*  />*/}
        {/*</div>*/}
        <div>{renderFlights(false)}</div>
        <div>{renderPaxs()}</div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.root}>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          variant="fullWidth"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="cancellation tabs"
        >
          {/* <Tab
            className={classes.tab}
            label={
              <Typography component={'span'}
                variant="h6"
                className={classes.tabLabel}
                style={{ color: tabValue === 0 ? '#092A5E' : 'white' }}
              >
                <span className={classes.tabNumber} style={{ backgroundColor: tabValue === 0 ? '#092A5E' : 'white' }}>
                  1
                </span>
                {intl.formatMessage({ id: 'cancel.cancelSelection' })}
              </Typography>
            }
          /> */}
          <Tab
            value={1}
            className={classes.tab}
            label={
              <Typography
                component={'span'}
                variant="h6"
                className={classes.tabLabel}
                style={{ color: tabValue === 1 ? '#092A5E' : 'white' }}
              >
                {/* <span className={classes.tabNumber} style={{ backgroundColor: tabValue === 1 ? '#092A5E' : 'white' }}>
                  2
                </span> */}
                {intl.formatMessage({ id: 'cancel.confirmCancellation' })}
              </Typography>
            }
            disabled
          />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          {renderSelection()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {flightCancellationOverview()}
        </TabPanel>
        {renderConfirmationDialog()}
        {renderSuccessfullyCancelResult()}
      </div>

      {window.ENV?.DEBUG_MODE && (
        <>
          <Row xs={12} className="d-flex flex-row align-items-center justify-content-between">
            <Col xs={12}>
              Debug: Voidable: {isVoidable ? 'YES' : 'NO'}, Payment: {hasPayment ? 'YES' : 'NO'}, refundToAccount:{' '}
              {numberFormatter.format(Number(refundToAccount))}, offerExpirationDateTime:{' '}
              {offerExpirationDateTime && format(new Date(offerExpirationDateTime), 'dd.MM.yyyy HH:mm')}
            </Col>
          </Row>
          {refundDetails ? <ReactJson name={'DEBUG:RefundDetails'} src={refundDetails} collapsed={false} /> : ''}
        </>
      )}
    </>
  );
};

export default CancelBooking;
