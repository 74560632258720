import {
  Alert,
  AlertTitle,
  Grid,
  IconButton,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { de, enUS } from 'date-fns/locale';
import { format, parse } from 'date-fns';

import { AmendTfaAncillaryLambdaRequest, IFee, Journey } from '@tff/types/TFF';

import FlightSegment from './FlightSegment';

import FlightTrack from './FlightTrack';

import { FlightIcon, GasStationIcon, SeatIcon } from '../icons';
import EuroIcon from '@mui/icons-material/Euro';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';
import { IntlMessages } from '../../util';
import { Seat6MComponent } from '../../util';
import { useNavigate } from 'react-router-dom';
import SeatsReservation from '../../util/Seats6M';
import { map6MResults } from '../../util/map6MResults';
import { useMutation } from 'react-query';
import { apigeeEndpoint } from '../../apis';
import { LoadingContext } from '../../contexts/LoadingContextProvider';
import { create6MSeatComponentConfig } from '../../util/map6MSeats';
import { useAmendBooking } from '../../hooks/use-amend-booking';
import { AmendBookingRequest } from '@tff/types/TFF/amend-booking-service';
import { PaxData } from '@tff/types/TFF/tfa-amend-ancillary-service';
import CustomDialog from '../Dialogs';
import { EnhancedAmendFailureDynamoItem } from '../../routes/amend-failures/amend-failures';

interface props {
  orderDetails: TFF.OrderDetails;
  journey: TFF.Journey;
  fee?: IFee;
  airports: TFF.IAirport[];
  disableButtons: boolean;
  bookingSource: string;
  recordLocator: string;
  tfmPnr: string;
  midocoOrderNo?: number;
}

export type NewSegment = TFF.Segment & { NewState?: string };

export const weekday = (dateString: string | undefined, siteLang: string): string => {
  let convertedDate = dateString;
  if (!dateString) {
    return '--';
  }

  let parsedDate: Date;
  if (dateString.indexOf('-') > -1) {
    parsedDate = parse(dateString!.substr(0, 10), 'yyyy-MM-dd', new Date());
  } else {
    parsedDate = parse(dateString!.substr(0, 10), 'dd.MM.yyyy', new Date());
  }
  convertedDate = format(parsedDate, 'eeee', {
    locale: siteLang === 'de' ? de : enUS,
  });

  return convertedDate;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '5px',
      borderColor: theme.palette.text.primary,
      margin: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '5px 20px',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      backgroundColor: theme.palette.text.primary,
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    seatsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      padding: '5px 20px',
      backgroundColor: '#E2F3FE',
    },
    menuButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
    },
    flightDetailRoot: {
      margin: '5px 0',
      witdh: '100%',
      '& .MuiCollapse-root': {
        width: '100%',
      },
      '& .MuiCollapse-entered': {
        width: '100%',
        marginBottom: '-20px',
      },
    },
    flightTrackLine: {
      height: '2px',
      backgroundColor: theme.palette.text.primary,
      width: '60%',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    flightTrackDot: {
      height: '12px',
      width: '12px',
      borderRadius: '6px',
      backgroundColor: theme.palette.text.primary,
    },
    seatsPriceWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    priceWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'end',
      width: '100%',
      padding: '5px 20px',
      backgroundColor: '#E2F3FE',
    },
  }),
);

const MuiAccordion = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'flex-end',
    '&::before': {
      display: 'none',
    },
  },
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    width: '14%',
    backgroundColor: '#DCDCDC',
    borderRadius: '5px',
    marginRight: '20px',
    minHeight: '28px',
    maxHeight: '28px',
  },
  content: {},
  expanded: {
    borderRadius: '0px',
    borderTopRightRadius: '5px ',
    borderTopLeftRadius: '5px',
  },
  expandIcon: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles({
  root: {
    backgroundColor: '#DCDCDC',
    display: 'block',
    width: '100%',
    marginBottom: '15px',
  },
})(AccordionDetails);

const FlightDetailCard: React.FC<props> = ({
  journey,
  orderDetails,
  fee,
  airports,
  bookingSource,
  recordLocator,
  tfmPnr,
  midocoOrderNo,
}) => {
  const { setAmendBookingRequest, amendResponse, amendStatus, amendError } = useAmendBooking();
  const [customDialogMsg, setCustomDialogMsg] = useState<string | undefined>(undefined);

  const segments: TFF.Segment[] = journey.Segments;
  const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });

  const changeView = useMediaQuery('(max-width: 1680px)');

  const classes = useStyles();
  const [toolTip, setToolTip] = useState<string[]>([]);
  const intl = useIntl();
  const theme = useTheme();

  const [openSeats, setOpenSeats] = useState<boolean>(false);
  const [scope, setScope] = useState<string>('*');

  const navigate = useNavigate();
  const { showLoading, closeLoading } = React.useContext(LoadingContext);

  const firstSegment: TFF.Segment | undefined = segments[0];
  const lastSegment = segments[segments.length - 1];
  const ancillaries: TFF.Ancillary[] | undefined = orderDetails?.Ancillaries
    ? Object.values(orderDetails.Ancillaries)
    : [];

  const journeySeats = ancillaries
    .filter((a: TFF.Ancillary) => a.JourneyOnd === journey.Ond && a.Type === 'SEAT')
    .map(seat => (seat.Value ? seat.Value : 'x'))
    .join(', ');

  const fares: TFF.Fare[] | undefined = orderDetails?.Fares ? Object.values<TFF.Fare>(orderDetails.Fares) : [];

  const journeyFares: TFF.FareProduct[] = [];

  fares.forEach(f => {
    f?.FareProducts?.forEach(fp => {
      if (fp.JourneyOnd === journey.Ond) journeyFares.push(fp);
    });
  });

  const renderFlightIcon = () => {
    return (
      <div className={classes.iconWrapper}>
        <FlightIcon />
      </div>
    );
  };

  const identifyState = (journey: Journey): string | undefined => {
    let ret = journey.State;

    journey.Segments.forEach(s => {
      if (s.State === 'UN') {
        ret = 'UN';
      }

      if (ret && s.State === 'TK') {
        ret = 'TK';
      }

      if (!ret && s.State === 'HK') {
        ret = 'HK';
      }
    });

    return ret;
  };

  const techStops = useMemo(() => {
    // Check for any technical stops in the flight
    const techStops = segments.flatMap(segment => (segment.TechStops ? segment.TechStops : []));

    return techStops;
  }, [segments]);

  const formatDate = dateString => {
    return new Intl.DateTimeFormat('de-DE', {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(dateString));
  };

  const renderTechStops = () => {
    if (techStops.length === 0) {
      return null;
    }

    return (
      <Alert severity="warning" style={{ margin: '5px' }}>
        <AlertTitle>
          <strong>
            {intl.formatMessage({ id: 'techStop.available' })} <GasStationIcon />
          </strong>
        </AlertTitle>

        {techStops.map(s => (
          <Typography key={s.Airport}>
            {intl.formatMessage({ id: 'techStop.airport' })}: <strong>{s.Airport}</strong> -{' '}
            {intl.formatMessage({ id: 'techStop.from' })} <strong>{formatDate(s.ArrivalTime)}</strong>{' '}
            {intl.formatMessage({ id: 'techStop.until' })} <strong>{formatDate(s.DepartureTime)}</strong>
          </Typography>
        ))}
      </Alert>
    );
  };

  const renderHeader = () => {
    return (
      <Grid container className={classes.flexCenter}>
        <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {renderFlightIcon()}
          <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>
              {firstSegment?.MarketingCarrier} {firstSegment?.FlightNumber} &nbsp;
            </strong>
            <p style={{ color: identifyState(journey) === 'UN' ? 'red' : theme.palette.text.primary }}>
              <small>({identifyState(journey)})</small>
            </p>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography component={'span'} variant="h6" color="primary">
                <strong>{airports?.find(item => item.id === firstSegment.Origin)?.nameDE}</strong>
              </Typography>
            </Grid>
            <Grid>
              <Typography component={'span'} variant="h6" color="primary">
                <strong>to</strong>
              </Typography>
            </Grid>
            <Grid item>
              <Typography component={'span'} variant="h6" color="primary">
                <strong>{airports?.find(item => item.id === lastSegment.Destination)?.nameDE}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (amendStatus === 'SUCCESS' && amendResponse?.query.action === 'validate') {
      setCustomDialogMsg(
        `To change your seat we will charge you ${amendResponse.bookingSum?.BalanceDue} Euro. Are you sure you want to proceed?`,
      );

      if (amendResponse.tfaAmendResponseDetails?.status === 'BOOKED_PARTIALLY') {
        const message: string = `There is an issue with your seat selection, we can't proceed with the change. Please check your seat selection and try again.
          ${amendResponse.tfaAmendResponseDetails?.issues.map(i => i.message).join('\n\r')}`;
        setCustomDialogMsg(message);
      }
      /*
      Here we are be able to validate the cost difference between the old and the new seat selection.
      At least we can use the BalancDue from the response to display a confirmation message.
      Also we are be able to validate if the seat reservation will be successful or not!
      */
    } else if (amendStatus === 'SUCCESS' && amendResponse?.query.action === 'confirm') {
      navigate(0);
      /*
      Here we are be able to show a confirmation dialog or a success message.
       */
    } else if (amendStatus === 'FAILURE') {
      if (amendResponse?.query.action === 'confirm') {
        /*
      Here we are be able to show a error message
       */
      }
      // setErrorDialog(true);
    }
  }, [amendStatus]);

  const seatsConfig = useMemo(() => create6MSeatComponentConfig([journey], orderDetails), [journey, orderDetails]);

  const handleAmendBooking = (action: 'validate' | 'confirm', paxData: PaxData[], sessionId?: string) => {
    setCustomDialogMsg(undefined);
    const amendBookingRequest: AmendBookingRequest = {
      action: action,
      bookingSource: 'TUI-NSK',
      recordLocator: recordLocator,
      tfmPnr: tfmPnr!,
      paxData: paxData,
      midocoOrderNo: midocoOrderNo,
      sessionId: sessionId,
    };
    setAmendBookingRequest(amendBookingRequest);
  };

  const handleCloseSeatsReservation = (data?: Seat6MComponent) => {
    setOpenSeats(false);
    if (!data) return;
    setScope(prev => (prev === '*' ? '+' : '*'));
    const seatAncillaries = ancillaries.filter((a: TFF.Ancillary) => a.JourneyOnd === journey.Ond && a.Type === 'SEAT');
    const mapData: AmendTfaAncillaryLambdaRequest = map6MResults(data.flights, recordLocator, seatAncillaries);
    handleAmendBooking('validate', mapData.paxData, undefined);
  };

  const renderFlightSeats = () => {
    return (
      <div className={classes.seatsWrapper}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SeatIcon />
          <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '10px' }}>
            <strong>{intl.formatMessage({ id: 'cancel.seatReservations' })}</strong>
          </Typography>
          <div className="d-flex flex-row align-items-center" style={{ marginLeft: 10 }}>
            <AirlineSeatReclineExtraIcon color="primary" />
            <Typography component={'span'} variant="h6" color="primary">
              {journeySeats ? journeySeats : intl.formatMessage({ id: 'cancel.noSeatFound' })}
            </Typography>
          </div>
          {bookingSource === 'TUI-NSK' && (
            <div style={{ marginLeft: 5 }}>
              <IconButton color="primary" onClick={() => setOpenSeats(true)}>
                {journeySeats ? <EditIcon /> : <AddIcon />}
              </IconButton>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFlightDetail = () => {
    return (
      <div className={classes.flightDetailRoot}>
        <MuiAccordion>
          <MuiAccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component={'span'}
              className={classes.heading}
              color="primary"
              style={{ fontSize: changeView ? '12px' : '15px' }}
            >
              <IntlMessages id="pages.flightDetails.flightDetails" />
            </Typography>
          </MuiAccordionSummary>
          <MuiAccordionDetails>
            {segments.map((segment: TFF.Segment, index: number) => {
              return (
                <FlightSegment
                  key={index}
                  tffSegment={segment as TFF.Segment}
                  orderDetails={orderDetails}
                  isNewOffer={false}
                />
              );
            })}
          </MuiAccordionDetails>
        </MuiAccordion>
      </div>
    );
  };

  const renderFlightPrice = () => {
    if (journeyFares && bookingSource === 'TUI-NSK') {
      let totalPrice = 0;
      journeyFares.forEach(j => {
        totalPrice += j?.BaseAmount as number;
      });
      return (
        <div className={classes.priceWrapper}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography component={'span'} variant="h6" color="primary" style={{ marginLeft: '10px' }}>
              <strong>{intl.formatMessage({ id: 'cancel.flightPrice' }).toUpperCase()}</strong>
            </Typography>
            <div className="d-flex flex-row align-items-center" style={{ marginLeft: 10 }}>
              <EuroIcon color="primary" />
              <Typography component={'span'} variant="h6" color="primary">
                {numberFormatter.format(+totalPrice)}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const JourneyHasChangeState = (): boolean =>
    React.useMemo(() => {
      let res = false;
      if (journey.AdditionalParams && journey.AdditionalParams[`TFF_ALERT:UNCONFIRMED_STATE`]) {
        setToolTip(old => {
          return [...old, journey.AdditionalParams![`TFF_ALERT:UNCONFIRMED_STATE`]];
        });
        res = true;
      }
      return res;
    }, [journey]);

  function handleConfirmSeatSelection() {
    setCustomDialogMsg(undefined);
    handleAmendBooking('confirm', amendResponse!.query!.paxData!, amendResponse!.sessionId!);
  }

  function handleAbordSeatSelection() {
    setCustomDialogMsg(undefined);
  }

  return (
    <>
      {customDialogMsg && (
        <CustomDialog
          initialOpen={true}
          onCancel={() => handleAbordSeatSelection()}
          onConfirm={() => handleConfirmSeatSelection()}
          width="md"
          cancelButtonText={intl.formatMessage({ id: 'abort' })}
          confirmButtonText={intl.formatMessage({ id: 'continue' })}
          closeOnBackdropClick={false}
          title={'Result of adding Seat: ' + amendResponse?.tfaAmendResponseDetails?.status}
          warning
          confirmDisabled={
            !amendResponse?.tfaAmendResponseDetails || amendResponse!.tfaAmendResponseDetails!.status !== 'BOOKED'
          }
        >
          <Typography variant="h6" color="primary">
            {customDialogMsg}
          </Typography>
        </CustomDialog>
      )}
      <Paper elevation={2} className={classes.root}>
        {JourneyHasChangeState() && (
          <Tooltip
            title={
              <>
                {toolTip.map(tip => (
                  <p key={tip}>{tip}</p>
                ))}
              </>
            }
          >
            <div className={classes.infoIcon}>!</div>
          </Tooltip>
        )}

        {renderHeader()}
        <div id="tfa">
          {
            //@ts-ignore
            <tui-tfa-ssr scope="scope_1" locale="de-DE"></tui-tfa-ssr>
          }
        </div>
        {journey && <FlightTrack journey={journey} />}
        {renderTechStops()}
        {renderFlightDetail()}
        <div className={classes.seatsPriceWrapper}>
          {renderFlightSeats()}
          {renderFlightPrice()}
        </div>

        {openSeats && (
          <div>
            <SeatsReservation
              initialOpen={openSeats}
              onClose={handleCloseSeatsReservation}
              scope={scope}
              configs={seatsConfig}
            />
          </div>
        )}
      </Paper>
    </>
  );
};

export default FlightDetailCard;
