import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export const AmendDialog = ({ handleClose, open, ssrs }) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Validation Error'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.formatMessage({ id: 'amend.DialogText' })}
          <br />
          {ssrs.map(i => (
            <li>{intl.formatMessage({ id: `ssr.${i}.shortDescription` })}</li>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {intl.formatMessage({ id: 'amend.Dialog' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
